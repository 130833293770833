import React, { useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col } from "react-bootstrap";
import {isDesktop, isMobile, isTablet} from "react-device-detect";
import { useStaticQuery, graphql, Link } from "gatsby"
import { useLocation } from "@reach/router";
import _ from "lodash";
import { PageLinks } from "../../common/page-links";
import { leadooPageSlot, viewing_btn_text, site_bviewing_agent_id } from "../../common/constant";
import LeadooProperty from "gatsby-theme-starberry-lomondgroup/src/components/LeadooScript/LeadooProperty";
import { getAgentId } from "gatsby-theme-starberry-lomondgroup/src/common/utils/property_utils";

import "gatsby-theme-starberry-lomondgroup/src/components/BottomBtn/Btn.scss";

const Bottom = ({ property_data, pageurl, setPlay, videoUrl }) => {
    const [modalShow, setModalShow] = React.useState(false);
    const location = useLocation();
    var property_url = location.href;

    //console.log("Book a Viewing", pageurl);

    const trackBookaViewing = () => {
        if (typeof window !== 'undefined') {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'Book a Viewing',
                'formType': pageurl,
                'formId': 'Book a Viewing',
                'formName': 'Book a Viewing - Rent',
                'formLabel': 'Book a Viewing'
            });
        }
    }
    
    const data = useStaticQuery(graphql`
    query OfficeContactQueryCustom{
      glstrapi {
        offices {
            office_crm_id
            mobile
            email
            letting_info {
                mobile
                longitude
                latitude
                email
                address
            }
          }
      }
    }`)

    var offices = data.glstrapi?.offices;

    let officeContact = _.find(offices, function (o) { return _.includes(_.split(o.office_crm_id, ','), property_data?.office_crm_id); });
    
    var to_email_id = process.env.GATSBY_BOOK_VIEWING_EMAIL ? process.env.GATSBY_BOOK_VIEWING_EMAIL : officeContact?.email ? officeContact?.email : "";

    var office_mobile = officeContact?.mobile

    console.log("officeContact", officeContact)

    if(property_data?.search_type === "lettings"){
        if(officeContact?.letting_info?.mobile){
            office_mobile = officeContact?.letting_info?.mobile
        }
        if(officeContact?.letting_info?.email){
            to_email_id = officeContact?.letting_info?.email
        }
    } 

    //console.log("officeContact", officeContact);

    //console.log("GATSBY_BOOK_VIEWING_LINK", process.env.GATSBY_BOOK_VIEWING_LINK, property_data.address);
    //var book_a_viewing_link = process.env.GATSBY_BOOK_VIEWING_LINK+property_data?.crm_id
    var book_a_viewing_link = process.env.GATSBY_BOOK_VIEWING_LINK;
    var agent_id = getAgentId(property_data?.office_crm_id);

    // let glasgow_crmid = "DJG,MUA,DJK,DJX,DJU"
    // let edinburgh_crmid = "DJC,MNG,DJD,DJA,DJH,DJW,DJT,DHO"
    // let dun_crmid = "DUN"

    // let aberdeen_crmid = "SLA,SLK"

    // if(property_data?.office_crm_id){
    //     if(dun_crmid.indexOf(property_data.office_crm_id) > -1){
    //         agent_id = "RFR-DJAlexanderDundee";
    //     }
    //     if(glasgow_crmid.indexOf(property_data.office_crm_id) > -1){
    //         agent_id = "RFR-DJAlexanderGlasgow";
    //     }
    //     if(edinburgh_crmid.indexOf(property_data.office_crm_id) > -1){
    //         agent_id = "RFR-DJAlexanderEdinburgh"
    //     }
    //     if(aberdeen_crmid.indexOf(property_data.office_crm_id) > -1){
    //         agent_id = "RFR-DJAlexanderAberdeen"
    //     }
    // }


    //console.log("property_data", property_data) ;

    
    if(process.env.GATSBY_BOOK_VIEWING_LINK){
        book_a_viewing_link = book_a_viewing_link.replace('{agent_id}', agent_id).replace('{unique_id}', property_data?.crm_id)
    }

    // let insight_report = process.env.GATSBY_AREA_INSIGHT_REPORT ? process.env.GATSBY_AREA_INSIGHT_REPORT : "";
    
    // if(property_data.latitude && property_data.longitude){
    //     insight_report += `&lat=${property_data.latitude}&lng=${property_data.longitude}`
    // }

    let book_a_viewing_txt = viewing_btn_text || "Book a Viewing"
    let book_a_viewing_txt_mbl = viewing_btn_text || "Viewing"

    return (
        <React.Fragment>
            <section className="bottom-btn d-xl-none">
                <Container>
                    <Row>
                        <Col>
                            <div className={`btn-wrapper ${property_data?.search_type === "sales" && officeContact?.office_crm_id ? "" : "full_wdth_btn"}`}>
                                
                                {process.env.GATSBY_AREA_INSIGHT_REPORT && 
                                    <a href={`${process.env.GATSBY_AREA_INSIGHT_REPORT}&lat=${property_data?.latitude}&lng=${property_data?.longitude}`} target="_blank" className={`btn`}>
                                        <div className="d-md-flex d-none">Get Your Intelligent <br/>Area Insights Report</div>
                                        <div className="d-flex d-md-none">Report</div>
                                    </a>
                                }

                                {process.env.GATSBY_SITE_NAME === "Sinclair" && videoUrl && 
                                    <a href={"javascript:;"} onClick={() => {setPlay(true)}} className="btn"
                                    > 
                                        <i className="video"></i> 
                                        <div className="d-md-flex d-none">Virtual Tour</div>
                                        <div className="d-flex d-md-none">Virtual Tour</div>
                                    </a>
                                }



                                {process.env.GATSBY_REGISTER_LINK && 
                                    <a href={process.env.GATSBY_REGISTER_LINK} target="_blank" className={`btn`}>
                                        <i className="icon-msg"></i> 
                                        <div className="d-md-flex d-none">Register for Interest</div>
                                        <div className="d-flex d-md-none">Register</div>
                                    </a>
                                }

                                {process.env.GATSBY_SITE_NAME  !== "Beals" && 
                                (
                                    (process.env.GATSBY_BOOK_VIEWING_LINK && property_data?.search_type === "lettings" && agent_id) ?
                                    <a href={"javascript:;"} onClick={() => {setModalShow(true);}} data-link={book_a_viewing_link}  className="btn"
                                    > 
                                        <i className="icon-msg"></i> 
                                        <div className="d-md-flex d-none">{book_a_viewing_txt}</div>
                                        <div className="d-flex d-md-none">{book_a_viewing_txt_mbl}</div>
                                    </a>
                                :   (
                                    property_data?.officeDepartment === "investments" ?
                                        <Link to={`/${PageLinks.contact_investment_centre}/`} state={{ property_data, form_type: "investment_centre", property_url: property_url, to_email_id }}  className="btn book_a_viewing" data-link={book_a_viewing_link}>
                                            <i className="icon-msg"></i> 
                                            <div className="d-md-flex d-none">Register for Interest</div>
                                            <div className="d-flex d-md-none">Register</div>
                                        </Link>
                                    :
                                        <Link to={`/${PageLinks.book_a_viewing}/`} state={{ property_data, form_type: "book_a_viewing", property_url: property_url, to_email_id }}  className="btn book_a_viewing" data-link={book_a_viewing_link}>
                                            <i className="icon-msg"></i> 
                                            <div className="d-md-flex d-none">{book_a_viewing_txt}</div>
                                            <div className="d-flex d-md-none">{book_a_viewing_txt_mbl}</div>
                                        </Link>
                                    )
                                )}

                                {process.env.GATSBY_SITE_NAME  === "Beals" && isDesktop &&
                                    (leadooPageSlot?.sell_property &&
                                        <LeadooProperty property_data={property_data} search_type={leadooPageSlot[property_data?.search_type]}/>
                                    )
                                }
                                
                                {office_mobile &&
                                    <a href={`tel:${office_mobile}`} className={`btn btn-outline mobile ${property_data?.search_type}`}>
                                        <i className="icon-phone"></i> 
                                        <div className="d-xl-flex d-none">{office_mobile}</div>
                                        <div className="d-flex d-xl-none">Call</div>
                                    </a>
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="book_a_viewing_ttl">
                        {book_a_viewing_txt}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="team_content col-md-12 col-lg-18 p-0">
                        <iframe src={book_a_viewing_link} height="600" width={"100%"} className="book_a_viewing" />
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};
export default Bottom;
